<template>
<div v-if="adReady" class="bg-white">
  <b-container fluid>
    <b-row>
      <b-col cols="8">
          <b-form-textarea
            id="textarea"
            v-model="toml"
            rows="30"
          ></b-form-textarea>
          <b-button @click="update">Update</b-button>
      </b-col>
      <b-col>
        <h3>Upload configuration</h3>
        <p>Upload configuration file from your computer. Uploaded configuration is not saved on-line. No changes made on-line will be saved.</p>
        <input type="file" ref="myFile" @change="selectedFile">
        <h3>Download configuration</h3>
        <p>Download current cofiguration for off-line editing</p>
        <b-button @click="saveFile">Download</b-button>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>
<script>
import { saveAs } from 'file-saver'
import { utParseData } from '@/libs/apps.js'
export default {
  components: {
  },
  computed: {
    ad: function () {
      return this.$store.state.appData
    },
    adReady: function () {
      if (this.ad.configuration) {
        return true
      }
      return false
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'UT app start', action: 'open UT app' })
    this.toml = this.$store.state.appDataToml
  },
  mounted: function () {
  },
  data () {
    return {
      timeline: null,
      toml: null
    }
  },
  methods: {
    selectedFile () {
      console.log('selected a file')
      console.log(this.$refs.myFile.files[0])
      let file = this.$refs.myFile.files[0]
      let reader = new FileReader()
      reader.readAsText(file, "UTF-8")
      reader.onload = evt => {
        this.toml = evt.target.result
        this.update()
      }
      reader.onerror = evt => {
        console.error(evt)
      }
    },
    saveFile: function () {
      const blob = new Blob([this.toml], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, this.ad.configuration.id + '.toml')
    },
    update: function () {
      const { toml, dbJson } = utParseData(this.toml)
      this.$store.commit('setAppData', dbJson)
      this.$store.commit('setAppDataToml', toml)
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/ h1 {
  margin-top: 4rem;
}

/deep/ h2 {
  margin-top: 3rem;
}

/deep/ h3 {
  margin-top: 2rem;
}

/deep/ h4 {
  margin-top: 1.5rem;
}
</style>
